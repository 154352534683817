import { addPathResolvers, setRouteDefaults } from "./utils/routes";
import { RoutesMap } from "./types";
import videoRoutes from './features/videos/routes'
import videoPlayerRoutes from './features/videoplayer/routes'
import playListRoutes from './features/playlist/routes'
import userRoutes from './features/users/routes'

const routeMap: RoutesMap = {
    ...videoRoutes,
    ...videoPlayerRoutes,
    ...playListRoutes,
    ...userRoutes,
};


const routes = addPathResolvers(setRouteDefaults(routeMap));
const routeList = Object.values(routes);
export const navRoutes = routeList.filter(r => r.includeInNav);
export { routeList };
export const offlineNavRoutes = [
    routes.login,
    routes.register,
];
export default routes;
