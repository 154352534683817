import Logout from "./views/Logout";
import SignIn from "./views/SignIn/SignIn";
import SignUp from "./views/SignUp/SignUp";

const routes = {
    logout: {
        title: 'Logout',
        path: '/logout',
        component: Logout,
        exact: true,
        authenticatedOnly: false,
    },
    login: {
        title: 'Login',
        path: '/login',
        component: SignIn,
        exact: true,
        authenticatedOnly: false,
    },
    register: {
        title: 'Register',
        path: '/register',
        component: SignUp,
        exact: true,
        authenticatedOnly: false,
    },
};

export default routes;
