import React from "react";
import "./SignIn.scss";
import { Link } from "react-router-dom";
import CustomButton from "../../../../components/netflix/CustomButton/CustomButton";
import FormInput from "../../../../components/netflix/FormInput/FormInput";
import backgroundImage from "../../../../assets/bg.jpg";

const SignIn = () => {
    const submit = () => {};
    return (
        <div className="signin">
            <div
                className="signin__bg"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            />
            <div className="signin__container">
                <div className="signin__shadow">
                    <h1 className="signin__title">Sign In</h1>

                    <form
                        action="POST"
                        autoComplete="new-password"
                        onSubmit={submit}
                    >
                        <FormInput
                            name="email"
                            type="email"
                            value={''}
                            handleChange={submit}
                            label="Email"
                            required
                        />

                        <FormInput
                            name="password"
                            type="password"
                            value={''}
                            handleChange={submit}
                            label="Password"
                            required
                        />

                        <div className="signin__btn-container">
                            <div className="signin__btn">
                                <CustomButton type="submit" signin>
                                    {" "}Sign In{" "}
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                    <div className="signin__option">
                        <span className="signin__option--newuser">New to Slownet?</span>
                        <Link to="/signup" className="signin__option--link">Sign up now.</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
