import { UserType } from "./types";
import { createSelector } from "reselect";

const USER_ACTIONS = {
    set: "SET_USER",
    logout: "LOGOUT_USER",
};

export const setCurrentUser = (user: UserType) => ({
    type: USER_ACTIONS.set,
    user,
});

export const logout = () => ({
    type: USER_ACTIONS.logout
});

export const DUMMY_USER = {
    thumbnail: require("../../assets/blankpf.png"),
    name: "Miguel Gomes",
    email: "Miguel.Gomes@madooz.com",
    id: 1,
} as UserType;

export const isLoggedInSelector = () => createSelector(
    (state: any) => state.user,
    (user: any) => !!(user && user.id)
);

export const userReducer = (state = DUMMY_USER , action) => {
    const set = (action) => action.user;
    const logout = () => ({});
    switch (action.type) {
    case USER_ACTIONS.set: return set(action);
    case USER_ACTIONS.logout: return logout();
    default: return state
    }
};
