import React, { useState } from 'react';
import { DUMMY_USER } from "../redux";
import styled from "styled-components";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import routes from "../../../routes";
import { Link } from "react-router-dom";

const Thumbnail = styled.img`
    border-radius: 30px;
    height: 40px;
    width: 40px;
    margin-right: -18px;
    margin-left: -6px;
`;

const UserMenu = () => {
    const user = DUMMY_USER;
    return (
        <Dropdown>
            <Dropdown.Toggle style={{ backgroundColor: 'unset', border: 0 }}>
                <Thumbnail src={user.thumbnail} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item eventKey="1" as={Link} to={routes.logout.getPath()}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserMenu;
