import {movieCategories} from "../../dummyData";

const ACTIONS = {
    set: "SET_VIDEOS"
};

export const setCurrentVideo = (videos) => ({
    type: ACTIONS.set,
    videos: videos || [],
});

type SetAction = ReturnType<typeof setCurrentVideo>;

const vids = Object.values(movieCategories).flatMap(v => v);

export const videosReducer = (state = vids , action) => {
    const setAction = (action) => action.videos;
    switch (action.type) {
    case ACTIONS.set: return setAction(action);
    default: return state
    }
};

const videosToCategories = videos => {
    const cats = {};
    videos.forEach(video => {
        const key = video.genre_ids[0];
        cats[key] = cats[key] || [];
        cats[key].push(video);
    });
    return cats
};

export const videoCategoriesReducer = (state = movieCategories, action) => {
    const setAction = (action: SetAction) => videosToCategories(action.videos);
    switch (action.type) {
    case ACTIONS.set: return setAction(action);
    default: return state
    }
};
