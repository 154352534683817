import React from 'react';
import { isLoggedInSelector } from "../redux";
import {useSelector} from "react-redux";

interface AuthenticatedOnlyProps {
    children: JSX.Element[] | JSX.Element
}

const AuthenticatedOnly = ({ children }: AuthenticatedOnlyProps) =>
    useSelector(isLoggedInSelector())
        ? <React.Fragment>{ children }</React.Fragment>
        : <div>403</div>;

export default AuthenticatedOnly;
