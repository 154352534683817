import React from 'react';
import { DUMMY_USER } from "../../users/redux";
import styled from 'styled-components'
import { useSelectPlaylistItems } from "../redux";
import ListItem from "../../../components/netflix/ListItem/ListItem";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import routes from "../../../routes";

const ListOverviewDiv = styled.div`
  margin-bottom: 30px;
  padding: 24px;
  padding-top: 84px;
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
`;

const ItemWrapper = styled.div`
    padding: 12px;
`;

const Playlist = () => {
    const currentUser = DUMMY_USER;
    const playlist = useSelectPlaylistItems();
    const firstVideo = playlist.length > 0 && playlist[0];
    return (
        <ListOverviewDiv>
            <h1>
                {
                    currentUser
                        ?`${currentUser.name}'s Playlist (${playlist.length})`
                        :`Anonymous User's Playlist (${playlist.length})`
                }
                {
                    firstVideo &&
                    <Link to={routes.videoPlayer.getPath({ id: firstVideo.id })}>
                        <FontAwesomeIcon
                            style={{marginLeft: 12, height: 24, marginBottom: 6}}
                            icon={faPlay}
                        />
                    </Link>
                }
            </h1>
            <h3>{ !currentUser && "Your list won't be saved because you are currently not logged in" }</h3>
            <ListWrapper>
                {playlist.map(item => (
                    <ItemWrapper key={item.id}>
                        <ListItem item={item} />
                    </ItemWrapper>
                ))}
            </ListWrapper>
        </ListOverviewDiv>
    );
};

export default Playlist;
