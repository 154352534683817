export const collections = [
    {
        id: 1,
        title: "Popular",
        start: 0,
        end: 10
    },
    {
        id: 2,
        title: "Top Rated",
        start: 10,
        end: 20
    },
    {
        id: 3,
        title: "Highest Grossing",
        start: 20,
        end: 30
    },
    {
        id: 4,
        title: "New Releases",
        start: 30,
        end: 40
    },
    {
        id: 5,
        title: "Most Liked",
        start: 40,
        end: 50
    },
    {
        id: 6,
        title: "Trending Now",
        start: 50,
        end: 60
    }
];


export const movieCategories = {
    "12": [
        {
            "popularity": 100.079,
            "vote_count": 1226,
            "video": false,
            "poster_path": "/zG2l9Svw4PTldWJAzC171Y3d6G8.jpg",
            "id": 385103,
            "adult": false,
            "backdrop_path": "/fKtYXUhX5fxMxzQfyUcQW9Shik6.jpg",
            "original_language": "en",
            "original_title": "Scoob!",
            "genre_ids": [
                12,
                16,
                35,
                9648,
                10751
            ],
            "title": "Scoob!",
            "vote_average": 8,
            "overview": "Bekijk in Scooby-Doo's grootste avontuur tot nu toe het nooit eerder vertelde verhaal over hoe levenslange vrienden Scooby en Shaggy elkaar voor het eerst ontmoetten en hoe ze de krachten bundelden met jonge detectives Fred, Velma en Daphne om het beroemde Mystery Inc. te vormen. Nu, met honderden Als de zaken zijn opgelost, staan ​​Scooby en de bende voor hun grootste en moeilijkste mysterie ooit: een boosaardig complot om de spookhond Cerberus op de wereld los te laten. Terwijl ze racen om deze wereldwijde 'dogpocalyps' te stoppen, ontdekt de bende dat Scooby een geheime nalatenschap en een epische bestemming heeft die groter is dan wie dan ook ooit had gedacht.",
            "release_date": "2020-05-15"
        },
        {
            "popularity": 84.927,
            "vote_count": 631,
            "video": false,
            "poster_path": "/mhDdx7o7hhrxrikq8aqPLLnS9w8.jpg",
            "id": 475430,
            "adult": false,
            "backdrop_path": "/o0F8xAt8YuEm5mEZviX5pEFC12y.jpg",
            "original_language": "en",
            "original_title": "Artemis Fowl",
            "genre_ids": [
                12,
                14,
                878,
                10751
            ],
            "title": "Artemis Fowl",
            "vote_average": 5.8,
            "overview": "Met de hulp van zijn trouwe beschermer Butler, zoekt de 12-jarige genie Artemis Fowl, afstammeling van een lange reeks criminele meesterbreinen, zijn mysterieus verdwenen vader en ontdekt daarmee een oude, ondergrondse beschaving - de verbazingwekkend geavanceerde wereld van feeën. Afleidend dat de verdwijning van zijn vader op de een of andere manier verbonden is met de geheimzinnige, teruggetrokken sprookjeswereld, verzint sluw Artemis een gevaarlijk plan - zo gevaarlijk dat hij uiteindelijk in een gevaarlijke oorlog van verstand terechtkomt met de almachtige feeën.",
            "release_date": "2020-06-12"
        },
        {
            "popularity": 78.496,
            "id": 420818,
            "video": false,
            "vote_count": 6325,
            "vote_average": 7.2,
            "title": "De Leeuwenkoning",
            "release_date": "2019-07-12",
            "original_language": "en",
            "original_title": "The Lion King",
            "genre_ids": [
                12,
                10751
            ],
            "backdrop_path": "/nRXO2SnOA75OsWhNhXstHB8ZmI3.jpg",
            "adult": false,
            "overview": "Simba is een jonge leeuw en zoon van leeuwenkoning Mufasa. Hij kijkt op tegen zijn vader en neemt zijn koninklijke bestemming ter harte. Dat zint Mufasa's jaloerse broer Scar absoluut niet en smeedt samen met een groep hyena's een plan om zowel Mufasa als Simba uit de weg te ruimen, zodat hij zelf de macht kan grijpen.",
            "poster_path": "/pSfwXxP94xktZKn3UaeVe6VdFZl.jpg"
        },
        {
            "popularity": 67.737,
            "vote_count": 2522,
            "video": false,
            "poster_path": "/3VqDLgKLfNYSQYEGC5sjGhcPhn7.jpg",
            "id": 508439,
            "adult": false,
            "backdrop_path": "/xFxk4vnirOtUxpOEWgA1MCRfy6J.jpg",
            "original_language": "en",
            "original_title": "Onward",
            "genre_ids": [
                12,
                16,
                35,
                14,
                10751
            ],
            "title": "Onward",
            "vote_average": 7.9,
            "overview": "De nieuwe Disney en Pixar film Onward vertelt het verhaal van twee tiener elvenbroers, die een bijzondere en avontuurlijke zoektocht beginnen om te ontdekken of er nog ergens in de wereld magie te vinden is. Deze nieuwe animatiefilm van Pixar Animation Studios is geregisseerd door Dan Scanlon en geproduceerd door Kori Rae - het team achter Monsters University.",
            "release_date": "2020-02-29"
        },
        {
            "popularity": 50.817,
            "vote_count": 917,
            "video": false,
            "poster_path": "/7W0G3YECgDAfnuiHG91r8WqgIOe.jpg",
            "id": 446893,
            "adult": false,
            "backdrop_path": "/qsxhnirlp7y4Ae9bd11oYJSX59j.jpg",
            "original_language": "en",
            "original_title": "Trolls World Tour",
            "genre_ids": [
                12,
                16,
                35,
                14,
                10402,
                10751
            ],
            "title": "Trolls World Tour",
            "vote_average": 7.6,
            "overview": "Queen Poppy en Branch doen een verrassende ontdekking - er zijn andere Troll-werelden dan hun eigen, en hun duidelijke verschillen zorgen voor grote botsingen tussen deze verschillende stammen. Wanneer een mysterieuze dreiging alle trollen over het hele land in gevaar brengt, moeten Poppy, Branch en hun vriendengroep een epische zoektocht beginnen om harmonie te creëren tussen de ruziënde trollen om ze te verenigen tegen een bepaalde ondergang.",
            "release_date": "2020-03-12"
        },
        {
            "popularity": 46.786,
            "vote_count": 13453,
            "video": false,
            "poster_path": "/7lyBcpYB0Qt8gYhXYaEZUNlNQAv.jpg",
            "id": 105,
            "adult": false,
            "backdrop_path": "/fq3wyOs1RHyz2yfzsb4sck7aWRG.jpg",
            "original_language": "en",
            "original_title": "Back to the Future",
            "genre_ids": [
                12,
                35,
                878,
                10751
            ],
            "title": "Back to the Future",
            "vote_average": 8.3,
            "overview": "Marty McFly  reist terug naar 1955 met een tot tijdmachine omgebouwde DeLorean van Doc Emmett Brown. Hij zorgt er per ongeluk voor dat zijn moeder verliefd op hem wordt in plaats van op zijn vader.",
            "release_date": "1985-07-03"
        },
        {
            "popularity": 44.126,
            "id": 177572,
            "video": false,
            "vote_count": 11862,
            "vote_average": 7.8,
            "title": "Big Hero 6",
            "release_date": "2014-10-24",
            "original_language": "en",
            "original_title": "Big Hero 6",
            "genre_ids": [
                12,
                10751,
                16,
                28,
                35
            ],
            "backdrop_path": "/4s2d3xdyqotiVNHTlTlJjrr3q0H.jpg",
            "adult": false,
            "overview": "Big Hero 6 draait om Hiro Hamada, een wonderkind en robotica-expert. Hij raakt betrokken bij een crimineel plan dat de hectische, hightech stad San Fransokyo dreigt te vernietigen. Met de hulp van zijn beste vriend – de robot Baymax – sluit Hiro zich aan bij een team van onervaren misdaadbestrijders om de stad te redden. Gebaseerd op de gelijknamige Marvel comics.",
            "poster_path": "/2mxS4wUimwlLmI1xp6QW6NSU361.jpg"
        },
        {
            "popularity": 43.985,
            "vote_count": 11920,
            "video": false,
            "poster_path": "/kYi7ZzUDhHQeGsvIjKQw1OhIxWC.jpg",
            "id": 269149,
            "adult": false,
            "backdrop_path": "/p2fRZzxla6NoRbIH2KOZq0gHb5S.jpg",
            "original_language": "en",
            "original_title": "Zootopia",
            "genre_ids": [
                12,
                16,
                35,
                10751
            ],
            "title": "Zootropolis",
            "vote_average": 7.7,
            "overview": "Zootropolis is een stad als geen ander, een moderne wereld met alleen dieren. De stad bestaat uit verschillende wijken zoals de chique Sahara Square en het ijskoude Tundratown. Het is een mengelmoes van dieren uit verschillende omgevingen die samenleven. Het maakt niet uit wie of wat je bent, van de grootste olifant tot de kleinste spitsmuis. Maar wanneer de optimistische officier Judy Hopps zich aansluit bij de politie, ontdekt ze dat het niet zo makkelijk is als eerste konijn bij het politiekorps vol grote, stoere dieren. Vastberaden om zichzelf te bewijzen, grijpt ze de kans aan om een zaak op te lossen. Zelfs als dat betekent dat ze moet samenwerken met de welbespraakte, sluwe vos Nick Wilde om het mysterie op te lossen.",
            "release_date": "2016-02-11"
        },
        {
            "popularity": 42.298,
            "id": 301528,
            "video": false,
            "vote_count": 5495,
            "vote_average": 7.6,
            "title": "Toy Story 4",
            "release_date": "2019-06-19",
            "original_language": "en",
            "original_title": "Toy Story 4",
            "genre_ids": [
                12,
                16,
                35,
                10751,
                14
            ],
            "backdrop_path": "/q62bpQ67qaXY0u6b2wFEnQYIbPd.jpg",
            "adult": false,
            "overview": "Woody en Bo Peep zijn stapelverliefd op elkaar en leven gelukkig samen. Dit maakt het des te meer pijnlijk wanneer zij plotseling wordt weggegeven. Hierop start Woody samen met zijn kameraad Buzz Lightyear een zoektocht om haar terug te krijgen.",
            "poster_path": "/w9kR8qbmQ01HwnvK4alvnQ2ca0L.jpg"
        },
        {
            "popularity": 41.134,
            "vote_count": 6401,
            "video": false,
            "poster_path": "/3iYQTLGoy7QnjcUYRJy4YrAgGvp.jpg",
            "id": 420817,
            "adult": false,
            "backdrop_path": "/v4yVTbbl8dE1UP2dWu5CLyaXOku.jpg",
            "original_language": "en",
            "original_title": "Aladdin",
            "genre_ids": [
                12,
                14,
                10749,
                10751
            ],
            "title": "Aladdin",
            "vote_average": 7.1,
            "overview": "De arme jongen Aladdin bevrijdt een geest uit een lamp en wordt daarmee de meest machtige jongen van het land. Met hulp van de geest probeert Aladdin het hart van de prinses te veroveren terwijl zij boze oom uit alle macht zich de lamp probeert toe te eigenen.",
            "release_date": "2019-05-22"
        },
        {
            "popularity": 36.156,
            "vote_count": 4205,
            "video": false,
            "poster_path": "/6IeGcoIEpOj7pUtzBk8zNM54YYl.jpg",
            "id": 44896,
            "adult": false,
            "backdrop_path": "/anSPPInN5tY6Bk1sOwLLTJAFzTq.jpg",
            "original_language": "en",
            "original_title": "Rango",
            "genre_ids": [
                12,
                16,
                35,
                37,
                10751
            ],
            "title": "Rango",
            "vote_average": 6.7,
            "overview": "Als een naamloze kameleon (Johnny Depp) uit zijn terrarium vrij komt, staat hij plots midden in de woestijn. Hij was zijn leventje tussen vier ramen toch al zat, maar wat nu? Een buidelrat stuurt hem op weg richting het stadje Dirt, ergens op de grens van beschaving. Daar doet de hagedis zich voor als Rango, een onverschrokken pistoolheld. Het stadje staat door gebrek aan water op het punt van uitdrogen. Het is aan Rango om Dirt van de ondergang te redden.",
            "release_date": "2011-03-02"
        },
        {
            "popularity": 34.019,
            "vote_count": 6954,
            "video": false,
            "poster_path": "/uyJgTzAsp3Za2TaPiZt2yaKYRIR.jpg",
            "id": 338952,
            "adult": false,
            "backdrop_path": "/qrtRKRzoNkf5vemO9tJ2Y4DrHxQ.jpg",
            "original_language": "en",
            "original_title": "Fantastic Beasts: The Crimes of Grindelwald",
            "genre_ids": [
                12,
                14,
                10751
            ],
            "title": "Fantastic Beasts: The Crimes of Grindelwald",
            "vote_average": 6.9,
            "overview": "In 1927, enkele maanden na de gebeurtenissen uit de eerste film, is Gellert Grindelwald ontsnapt en is hij medestanders naar zijn kant aan het overhalen. Dit met de belofte om tovenaars te verheffen boven alle niet-magische wezens.",
            "release_date": "2018-11-14"
        },
        {
            "popularity": 33.893,
            "id": 808,
            "video": false,
            "vote_count": 10812,
            "vote_average": 7.6,
            "title": "Shrek",
            "release_date": "2001-05-18",
            "original_language": "en",
            "original_title": "Shrek",
            "genre_ids": [
                12,
                16,
                35,
                10751,
                14
            ],
            "backdrop_path": "/2l5UHZBcp9cx1PwKLdisJ0gV9jB.jpg",
            "adult": false,
            "overview": "De slechte heerser van Duloc (de kleine Lord Farquaad) heeft alle sprookjesfiguren uit zijn land verbannen. De figuren, inclusief een praatgrage ezel, vluchten naar het moerasland van de oger Shrek, die erg op z'n privacy gesteld is.",
            "poster_path": "/msybfhqM2au2BhC1gnQqDOJCBa7.jpg"
        },
        {
            "popularity": 32.3,
            "vote_count": 5359,
            "video": false,
            "poster_path": "/jNB2zXgLG8D98X1kYwERVlrW3Oa.jpg",
            "id": 158852,
            "adult": false,
            "backdrop_path": "/8Nn6rRClhREixqsHJ3eAnZNVfJl.jpg",
            "original_language": "en",
            "original_title": "Tomorrowland",
            "genre_ids": [
                12,
                9648,
                878,
                10751
            ],
            "title": "Project T",
            "vote_average": 6.2,
            "overview": "Voormalig wonderkind Frank, getekend door teleurstelling, en Casey, een pientere, optimistische tiener die barst van de wetenschappelijke nieuwsgierigheid, delen hetzelfde lot. Samen gaan ze op een gevaarlijke missie om de geheimen te onthullen van een mysterieuze plek die zich in een andere dimensie bevindt, en alleen bekend staat als Tomorrowland. Na wat ze daar moeten doen zal de wereld — en zijzelf — nooit meer hetzelfde zijn.",
            "release_date": "2015-05-19"
        },
        {
            "popularity": 32.27,
            "vote_count": 11699,
            "video": false,
            "poster_path": "/26CvDUwEFj8re7avUpvfoVgS8lw.jpg",
            "id": 354912,
            "adult": false,
            "backdrop_path": "/gKZAQ3b9yApxFunUBlS5Mp74QG4.jpg",
            "original_language": "en",
            "original_title": "Coco",
            "genre_ids": [
                12,
                16,
                35,
                14,
                10751
            ],
            "title": "Coco",
            "vote_average": 8.2,
            "overview": "Miguel droomt ervan om een succesvol muzikant te worden. Ondanks dat muziek al generaties lang uit zijn familie wordt verbannen, is het Miguel’s grootste droom om net zo groot te worden als zijn idool, Ernesto de la Cruz.",
            "release_date": "2017-10-27"
        },
        {
            "popularity": 31.827,
            "vote_count": 14194,
            "video": false,
            "poster_path": "/h6NYfVUyM6CDURtZSnBpz647Ldd.jpg",
            "id": 259316,
            "adult": false,
            "backdrop_path": "/7GrpqAs0oDcFcwFwyygnUI7BrZA.jpg",
            "original_language": "en",
            "original_title": "Fantastic Beasts and Where to Find Them",
            "genre_ids": [
                12,
                14,
                10751
            ],
            "title": "Fantastic Beasts and Where to Find Them",
            "vote_average": 7.4,
            "overview": "In 1926 arriveert Newt Scamander bij het Magische Congres van de Verenigde Staten met een magische koffer die een aantal gevaarlijke wezens en hun leefomgeving bevat. Wanneer de wezens ontsnappen gaan de Amerikaanse Magische Authoriteiten tot vervolging van Newt over.",
            "release_date": "2016-11-16"
        },
        {
            "popularity": 31.801,
            "id": 674,
            "video": false,
            "vote_count": 13587,
            "vote_average": 7.8,
            "title": "Harry Potter en de Vuurbeker",
            "release_date": "2005-11-16",
            "original_language": "en",
            "original_title": "Harry Potter and the Goblet of Fire",
            "genre_ids": [
                12,
                14,
                10751
            ],
            "backdrop_path": "/8f9dnOtpArDrOMEylpSN9Sc6fuz.jpg",
            "adult": false,
            "overview": "Harry gaat samen met zijn vrienden Ron en Hermione naar het WK Quidditch. Helaas loopt dit evenement mis doordat een groep volgelingen van Voldemort, ook wel Dooddoeners genoemd, door het veld parradeert.",
            "poster_path": "/kSuMcBVh8F8ISk0aVu8gO0LyWDy.jpg"
        },
        {
            "popularity": 31.736,
            "id": 181533,
            "video": false,
            "vote_count": 4246,
            "vote_average": 6.2,
            "title": "Night at the Museum: Secret of the Tomb",
            "release_date": "2014-12-17",
            "original_language": "en",
            "original_title": "Night at the Museum: Secret of the Tomb",
            "genre_ids": [
                12,
                35,
                14,
                10751
            ],
            "backdrop_path": "/7ZAvSUaxuVg9ORCVguDx3NOE5Gn.jpg",
            "adult": false,
            "overview": "Larey Daley weet dat een Egyptisch tablet uit de tijd van de farao's het geheim is achter de tot leven komende museumstukken in het \"Museum of Natural History\". Maar wanneer dit magisch tablet tekenen van corrosie begint te vertonen, reist Larry met onder andere Farao Ahkmenrah, Teddy Roosevelt en Attila de Hun af naar het \"British Museum\" in Londen om het te redden van zijn ondergang.",
            "poster_path": "/ipZlg4EVo4kj2TjiR0hfSYdEzbd.jpg"
        },
        {
            "popularity": 30.62,
            "vote_count": 3598,
            "video": false,
            "poster_path": "/c03kHoIsEVrsVBh7wRHNQ8JgvwP.jpg",
            "id": 9340,
            "adult": false,
            "backdrop_path": "/zR9lAU4j6RXxPd1nuNtt1REkTYG.jpg",
            "original_language": "en",
            "original_title": "The Goonies",
            "genre_ids": [
                12,
                35,
                10751
            ],
            "title": "The Goonies",
            "vote_average": 7.5,
            "overview": "De Goonies, een stel jonge tieners, vinden de plattegrond van de piraat Eénoog Willy en ze gaan meteen op jacht. Deze plattegrond leidt namelijk naar een schat. Al snel komen ze in een kilometerslang gangenstelsel terecht en beleven de gekste avonturen.",
            "release_date": "1985-06-07"
        },
        {
            "popularity": 28.206,
            "vote_count": 6164,
            "video": false,
            "poster_path": "/tOEOlmLP71IojeJ91dyJ9Nsb8O8.jpg",
            "id": 278927,
            "adult": false,
            "backdrop_path": "/tB2w4m0rW62MTufTjRj0gFLMVBP.jpg",
            "original_language": "en",
            "original_title": "The Jungle Book",
            "genre_ids": [
                12,
                18,
                14,
                10751
            ],
            "title": "The Jungle Book",
            "vote_average": 6.8,
            "overview": "The Jungle Book gaat over Mowgli, een mensenjong dat opgroeit bij een wolvenroedel. Mowgli voelt zich niet langer thuis in de jungle wanneer de angstaanjagende Shere Khan, de tijger die zichtbaar getekend is door de mens, bezweert alles te elimineren wat hij als een bedreiging ziet. Als Mowgli de enige plek die hij kent moet verlaten, begint hij aan een fascinerende reis op zoek naar zichzelf. In gezelschap van zijn strenge mentor, panter Bagheera, en de zorgeloze beer Baloo komt hij onderweg junglebewoners tegen die nu niet bepaald het beste met hem voorhebben. Kaa, probeert het mensenjong te hypnotiseren met haar verleidelijke blik en stem, en koning Louie, probeert Mowgli het geheim te laten prijsgeven. Het geheim van de ongrijpbare en dodelijke rode bloem: vuur.",
            "release_date": "2016-04-07"
        },
        {
            "popularity": 27.791,
            "vote_count": 2482,
            "video": false,
            "poster_path": "/vbdYFtDIxMaDq9IaeZW9CLIQXKM.jpg",
            "id": 313297,
            "adult": false,
            "backdrop_path": "/2nGKhDksmoDdkllXm5D5gy2urCg.jpg",
            "original_language": "en",
            "original_title": "Kubo and the Two Strings",
            "genre_ids": [
                12,
                16,
                10751
            ],
            "title": "Kubo en het Magische Zwaard",
            "vote_average": 7.7,
            "overview": "Wanneer de slimme en goedhartige jonge verhalenverteller Kubo per ongeluk een wraakzuchtige geest oproep, moet hij het mysterie van zijn overleden vader, de beste samurai die ooit heeft geleefd, zien op te lossen.",
            "release_date": "2016-08-18"
        }
    ],
    "14": [
        {
            "popularity": 40.437,
            "id": 420809,
            "video": false,
            "vote_count": 3226,
            "vote_average": 7.3,
            "title": "Maleficent: Mistress of Evil",
            "release_date": "2019-10-16",
            "original_language": "en",
            "original_title": "Maleficent: Mistress of Evil",
            "genre_ids": [
                14,
                10751,
                12
            ],
            "backdrop_path": "/skvI4rYFrKXS73BJxWGH54Omlvv.jpg",
            "adult": false,
            "overview": "'Maleficent: Mistress of Evil' volgt de complexe relatie tussen de heks Maleficent en prinses Aurora. Het is inmiddels enkele jaren geleden dat Maleficent besloot om Aurora te vervloeken. Samen moeten ze nu nieuwe bondgenoten vinden in hun pogingen de magische wezens van de wereld te beschermen.  Wanneer Prince Philip het eindelijk aandurft Aurora ten huwelijk te vragen, lijkt het erop dat er een einde komt aan de vijandigheid die al generaties heerst tussen Ulstead en The Moors. Aurora weet haar stiefmoeder Maleficent zelfs over te halen om in te gaan op een uitnodiging in het kasteel van koning John en koningin Ingrith",
            "poster_path": "/dcKpwBPc5F3d8nXYdvunLGqVzAI.jpg"
        },
        {
            "popularity": 34.659,
            "id": 8392,
            "video": false,
            "vote_count": 4658,
            "vote_average": 8.1,
            "title": "Tonari no Totoro",
            "release_date": "1988-04-16",
            "original_language": "ja",
            "original_title": "となりのトトロ",
            "genre_ids": [
                14,
                16,
                10751
            ],
            "backdrop_path": "/95ozIP0A2fKaAXxwDxUEVn74Iux.jpg",
            "adult": false,
            "overview": "Twee meisjes verhuizen met hun vader naar het platteland om in de buurt te zijn van het verpleegtehuis waar hun moeder ligt. Satsuki en Mei ontdekken dat het nabijgelegen bos bevolkt is door wonderlijke wezens.",
            "poster_path": "/ykeaF3Ldz1Kf6sfnOJJH4dW56F4.jpg"
        }
    ],
    "16": [
        {
            "popularity": 83.894,
            "id": 166428,
            "video": false,
            "vote_count": 3671,
            "vote_average": 7.8,
            "title": "Hoe Tem Je Een Draak 3",
            "release_date": "2019-01-03",
            "original_language": "en",
            "original_title": "How to Train Your Dragon: The Hidden World",
            "genre_ids": [
                16,
                10751,
                12
            ],
            "backdrop_path": "/lFwykSz3Ykj1Q3JXJURnGUTNf1o.jpg",
            "adult": false,
            "overview": "Hiccup probeert zijn droom te vervullen door een vreedzame draken-utopie te creëren. Ondertussen ontdekt Toothless een ongetemde, ongrijpbare partner die ervoor zorgt dat de Night Fury vertrekt.",
            "poster_path": "/rE5lgmnQZ3xQaAZhHu6q0j0HCGh.jpg"
        },
        {
            "popularity": 77.619,
            "id": 330457,
            "video": false,
            "vote_count": 5386,
            "vote_average": 7.3,
            "title": "Frozen II",
            "release_date": "2019-11-20",
            "original_language": "en",
            "original_title": "Frozen II",
            "genre_ids": [
                16,
                10751,
                12
            ],
            "backdrop_path": "/xJWPZIYOEFIjZpBL7SVBGnzRYXp.jpg",
            "adult": false,
            "overview": "Na de gebeurtenissen uit 'Frozen' heeft er enige tijd vrede geheerst in het koninkrijk Arendelle. Elsa, Anna, Kristoff en Olaf moeten vervolgens een nieuw avontuur aangaan, waarvoor ze diep de bossen intrekken. Reden van hun vertrek is om een eeuwenoud mysterie over hun koninkrijk te ontdekken.",
            "poster_path": "/pjeMs3yqRmFL3giJy4PMXWZTTPa.jpg"
        },
        {
            "popularity": 54.336,
            "vote_count": 240,
            "video": false,
            "poster_path": "/8unSdNWPrp2oWDTz5fxxipj90LD.jpg",
            "id": 486589,
            "adult": false,
            "backdrop_path": "/bga3i5jcejBekr2FCGJga1fYCh.jpg",
            "original_language": "en",
            "original_title": "Red Shoes and the Seven Dwarfs",
            "genre_ids": [
                16,
                10749,
                10751
            ],
            "title": "Roodschoentje en de zeven dwergen",
            "vote_average": 7.1,
            "overview": "Een parodie op het bekende sprookje van Sneeuwwitje. Zeven dwergen zijn op zoek naar de rode schoenen die ervoor zorgen dat Sneeuwwitje een slank figuur krijgt. De dwergen zijn immers vervloekte ijdele prinsen en kunnen hun vloek pas doorbreken wanneer ze in bezit zijn van de schoenen.",
            "release_date": "2019-07-25"
        },
        {
            "popularity": 45.336,
            "id": 109445,
            "video": false,
            "vote_count": 12066,
            "vote_average": 7.3,
            "title": "Frozen",
            "release_date": "2013-11-27",
            "original_language": "en",
            "original_title": "Frozen",
            "genre_ids": [
                16,
                12,
                10751
            ],
            "backdrop_path": "/ggCYp26DcaBiGsU2KdVSlNg3y4L.jpg",
            "adult": false,
            "overview": "De bergbeklimmer Kristoff en een jong meisje genaamd Anna reizen met een stel rendieren over de besneeuwde bergtoppen en gevaarlijke kliffen. Ze zijn op zoek naar Anna's zus Elsa, de legendarische Sneeuwkoningin, om de eeuwigdurende winter die over hun koninkrijk gevallen is te beëindigen. Op hun tocht ontmoeten ze mystieke trollen en de hilarische sneeuwman Olaf en zullen ze de natuurelementen moeten overwinnen om het koninkrijk te redden.",
            "poster_path": "/itAKcobTYGpYT8Phwjd8c9hleTo.jpg"
        },
        {
            "popularity": 40.942,
            "id": 862,
            "video": false,
            "vote_count": 12628,
            "vote_average": 7.9,
            "title": "Toy Story",
            "release_date": "1995-10-30",
            "original_language": "en",
            "original_title": "Toy Story",
            "genre_ids": [
                16,
                35,
                10751
            ],
            "backdrop_path": "/lxD5ak7BOoinRNehOCA85CQ8ubr.jpg",
            "adult": false,
            "overview": "Cowboy Woody is al jaren het favoriete speelgoed van Andy. Wanneer de jongen voor zijn verjaardag de blitse astronautenpop Buzz Lightyear krijgt, wordt Woody naar de achtergrond verdrongen. De twee stukken speelgoed wedijveren om de gunst van de jongen en zetten door hun geruzie de hele kinderkamer in rep en roer. Als Buzz door toedoen van Woody uit het raam valt, krijgt Woody hiervan de schuld van de rest van het speelgoed. Hij gaat naar buiten om Buzz proberen te redden.",
            "poster_path": "/uXDfjJbdP4ijW5hWSBrPrlKpxab.jpg"
        },
        {
            "popularity": 40.935,
            "id": 129,
            "video": false,
            "vote_count": 9787,
            "vote_average": 8.5,
            "title": "Spirited Away",
            "release_date": "2001-07-20",
            "original_language": "ja",
            "original_title": "千と千尋の神隠し",
            "genre_ids": [
                16,
                10751,
                14
            ],
            "backdrop_path": "/Ab8mkHmkYADjU7wQiOkia9BzGvS.jpg",
            "adult": false,
            "overview": "Tijdens de verhuizing van haar familie naar een buitenwijk wandelt Chihiro, een 10-jarig meisje, een wereld binnen die geregeerd wordt door heksen en monsters, waar mensen veranderd worden in dieren. Chihiro moet in een groot badhuis werken om te overleven. Zal het haar lukken om terug te keren naar haar eigen wereld?",
            "poster_path": "/8TEs6twIANAc2qiU8m17JO8Ujcx.jpg"
        },
        {
            "popularity": 39.118,
            "vote_count": 12935,
            "video": false,
            "poster_path": "/3mTsvFLXlfplP6aIqwvvkpHV4l.jpg",
            "id": 585,
            "adult": false,
            "backdrop_path": "/sDTnMOJ3H5wI38OxObmCtK7wfd5.jpg",
            "original_language": "en",
            "original_title": "Monsters, Inc.",
            "genre_ids": [
                16,
                35,
                10751
            ],
            "title": "Monsters en Co.",
            "vote_average": 7.8,
            "overview": "Monsters, Incorporated is de grootste bangmaak-fabriek ter wereld, en Sulley is één van de beste medewerkers. Het is de taak van Sulley en z'n collega's om kleine kinderen bang te maken, en vervolgens de angstkreten (die als energiebron gebruikt worden) op te vangen.",
            "release_date": "2001-11-01"
        },
        {
            "popularity": 37.552,
            "id": 62177,
            "video": false,
            "vote_count": 9720,
            "vote_average": 7,
            "title": "Brave",
            "release_date": "2012-06-21",
            "original_language": "en",
            "original_title": "Brave",
            "genre_ids": [
                16,
                12,
                35,
                10751,
                28,
                14
            ],
            "backdrop_path": "/qx9ts2hBYJrkIQxhryitxnLlm2u.jpg",
            "adult": false,
            "overview": "Merida is een gepassioneerde dame met koninklijke ouders, die niets liever wil dan een geweldige boogschutter worden. Wanneer Merida tijdens een meningverschil met haar moeder een roekeloze beslissing neemt, brengt ze het koninkrijk van haar vader en het leven van haar moeder in groot gevaar. Merida doet er alles aan om dit alles terug te draaien en krijgt daarbij te maken met onvoorspelbare natuurkrachten, magie en een duistere, eeuwenoude vloek...",
            "poster_path": "/fg57nIBn2xQw8JZKIX6AzMa3pEp.jpg"
        },
        {
            "popularity": 33.999,
            "id": 12,
            "video": false,
            "vote_count": 13760,
            "vote_average": 7.8,
            "title": "Finding Nemo",
            "release_date": "2003-05-30",
            "original_language": "en",
            "original_title": "Finding Nemo",
            "genre_ids": [
                16,
                10751
            ],
            "backdrop_path": "/dFYguAfeVt19qAbzJ5mArn7DEJw.jpg",
            "adult": false,
            "overview": "De clownvis Marlin leidt samen met zijn enige zoon Nemo een veilig leventje in het Great Barrier Reef bij Australië. Wanneer Nemo onverwachts weggehaald wordt van zijn thuis, en in het aquarium van een tandarts belandt, is het aan Marlin om hem te redden.",
            "poster_path": "/xVNSgrsvpcAHPnyKf2phYxyppNZ.jpg"
        },
        {
            "popularity": 32.64,
            "id": 10193,
            "video": false,
            "vote_count": 10422,
            "vote_average": 7.8,
            "title": "Toy Story 3",
            "release_date": "2010-06-16",
            "original_language": "en",
            "original_title": "Toy Story 3",
            "genre_ids": [
                16,
                10751,
                35
            ],
            "backdrop_path": "/wE5JGzujfvDPMIfFjJyrhXFjZLc.jpg",
            "adult": false,
            "overview": "Wanneer Andy gaat studeren en op kamers gaat, komen Woody, Buzz en de rest van hun speelgoedvrienden bij dagopvang Sunnyside terecht.",
            "poster_path": "/4cpGytCB0eqvRks4FAlJoUJiFPG.jpg"
        },
        {
            "popularity": 32.291,
            "vote_count": 5361,
            "video": false,
            "poster_path": "/lW0kUtXTOVlHTVhDO2VzNBACAHX.jpg",
            "id": 335797,
            "adult": false,
            "backdrop_path": "/z9ft5HYHzWcasR6SGcgeluxTznB.jpg",
            "original_language": "en",
            "original_title": "Sing",
            "genre_ids": [
                16,
                35,
                18,
                10751
            ],
            "title": "Sing",
            "vote_average": 7,
            "overview": "Buster Moon is een erg enthousiaste en optimistische koala die de eigenaar is van een slecht draaiend theater. Om zijn schulden af te lossen en de lege zalen te vullen, besluit hij om een grootse zangwedstrijd te organiseren. Buster weet nog net 1000 dollar bijeen te schrapen als prijzengeld voor de wedstrijd. Door een fout van zijn assistent, een oude hagedis, staat echter op de flyers dat er 100.000 dollar te winnen is.",
            "release_date": "2016-11-23"
        },
        {
            "popularity": 32.058,
            "vote_count": 12648,
            "video": false,
            "poster_path": "/md1eKDnWFU9s4hqtkHlQtmssZU5.jpg",
            "id": 8587,
            "adult": false,
            "backdrop_path": "/wXsQvli6tWqja51pYxXNG1LFIGV.jpg",
            "original_language": "en",
            "original_title": "The Lion King",
            "genre_ids": [
                16,
                18,
                10751
            ],
            "title": "De Leeuwenkoning",
            "vote_average": 8.2,
            "overview": "In Afrika wordt de jonge leeuw Simba geboren als zoon van de leeuwenkoning Mufasa. Dat zint Mufasa's jaloerse broer Scar absoluut niet, die nu niet langer de eerste troonopvolger is. Terwijl Simba een onbezorgde jeugd geniet in zijn vaders koninkrijk, smeedt Scar samen met een groep hyena's een plan om zowel Mufasa als Simba uit de weg te ruimen, en zo zelf de macht te kunnen grijpen.",
            "release_date": "1994-06-23"
        },
        {
            "popularity": 31.516,
            "vote_count": 9493,
            "video": false,
            "poster_path": "/yFWQkz2ynjwsazT6xQiIXEUsyuh.jpg",
            "id": 863,
            "adult": false,
            "backdrop_path": "/91qfG6VaxRsCh1SbIi1w2vxknsL.jpg",
            "original_language": "en",
            "original_title": "Toy Story 2",
            "genre_ids": [
                16,
                35,
                10751
            ],
            "title": "Toy Story 2",
            "vote_average": 7.5,
            "overview": "Terwijl Andy op zomerkamp is, ziet speelgoedverzamelaar Al McWiggin Woody liggen in de tuin en besluit hem mee te nemen. Al snel wordt Woody gemist door zijn kameraad Buzz Lightyear, die een reddingsactie op touw zet. Voor het eerst moeten de speelgoedvrienden de echte wereld verkennen om Woody te redden.",
            "release_date": "1999-10-30"
        },
        {
            "popularity": 30.481,
            "vote_count": 2240,
            "video": false,
            "poster_path": "/fMOr9nBa4sOCvFgsTU9EhEpecLJ.jpg",
            "id": 73723,
            "adult": false,
            "backdrop_path": "/82py61aNx2UzZ1rkwWo5p6170li.jpg",
            "original_language": "en",
            "original_title": "The Lorax",
            "genre_ids": [
                16,
                10751
            ],
            "title": "De Lorax en het Verdwenen Bos",
            "vote_average": 6.4,
            "overview": "Wanneer Ted er achter komt dat Audrey het allerliefste een echte levende boom wil zien, gaat hij op zoek. Zijn oma weet hem te vertellen dat \"The Once-ler\" het geheim achter de verdwijning van de bomen en de \"Lorax\" kent. Ted moet zich buiten zijn veilige stad wagen om het mysterie te ontrafelen en zo het hart van zijn droommeisje te winnen. Mr. O'Hare, een harteloze ondernemer die zijn geld verdient met de verkoop van schone lucht, wil koste wat kost een stokje steken voor de terugkeer van levende bomen.",
            "release_date": "2012-03-01"
        },
        {
            "popularity": 30.017,
            "id": 10681,
            "video": false,
            "vote_count": 13040,
            "vote_average": 8,
            "title": "WALL-E",
            "release_date": "2008-06-22",
            "original_language": "en",
            "original_title": "WALL·E",
            "genre_ids": [
                16,
                10751,
                878
            ],
            "backdrop_path": "/ai2FicMUxLCurVkjtYdSvVDWRmS.jpg",
            "adult": false,
            "overview": "Zevenhonderd eenzame jaren doet de robot WALL-E waarvoor hij gemaakt is: het schoonhouden van de aarde. Zijn enige vertier lag in het verzamelen van prullaria. Maar dan ontmoet hij een zoekrobot genaamd EVE. Samen reizen ze door het hele heelal en beginnen aan een van de meest opwindende en fantasierijke avonturen allertijden.",
            "poster_path": "/3g8vyePqVatTaUSnrNnrrwguhxM.jpg"
        },
        {
            "popularity": 29.722,
            "vote_count": 978,
            "video": false,
            "poster_path": "/q1epO0eO8DWu8Vo8tPfvVlzW48T.jpg",
            "id": 481084,
            "adult": false,
            "backdrop_path": "/ur4NTeFGZmQ6Hz5uEkAMgPI3WRg.jpg",
            "original_language": "en",
            "original_title": "The Addams Family",
            "genre_ids": [
                16,
                35,
                14,
                10751
            ],
            "title": "The Addams Family",
            "vote_average": 6.4,
            "overview": "Het leven van de familie Addams wordt helemaal op zijn kop gezet wanneer ze ontdekt dat de nabije buurtbewoners zich plotseling zenuwachtig gedragen. Ze worden aangespoord door een televisiepresentatrice die maar al te graag van een gekke familie als de Addams vanaf wil geraken. Alsof dat niet genoeg is, moet Wednesday voor de allereerste keer naar een openbare school en bereidt Pugsley zich voor op een belangrijk optreden.",
            "release_date": "2019-10-10"
        },
        {
            "popularity": 29.667,
            "id": 10144,
            "video": false,
            "vote_count": 5286,
            "vote_average": 7.3,
            "title": "De kleine zeemeermin",
            "release_date": "1989-11-17",
            "original_language": "en",
            "original_title": "The Little Mermaid",
            "genre_ids": [
                16,
                10751,
                14
            ],
            "backdrop_path": "/4w2uPtIo0lFSnQnWg0zVAqPJCLP.jpg",
            "adult": false,
            "overview": "Een heetgebakerde meerminprinses, Ariel, wordt verliefd op de menselijke Prins Eric. De zeeheks Ursula kan haar helpen, maar... voor wat, hoort wat!",
            "poster_path": "/ysCM6jrrFja3n3TR806F9LcqOjK.jpg"
        },
        {
            "popularity": 29.198,
            "id": 812,
            "video": false,
            "vote_count": 8057,
            "vote_average": 7.6,
            "title": "Aladdin",
            "release_date": "1992-11-25",
            "original_language": "en",
            "original_title": "Aladdin",
            "genre_ids": [
                16,
                10751,
                12,
                14,
                10749
            ],
            "backdrop_path": "/5OeY4U2rzePxWq2rkqMajUx2gz7.jpg",
            "adult": false,
            "overview": "Aladdin is een straatjongen die samen met zijn aapje Abu in Agrabah woont. Als Prinses Jasmine het leven in het paleis zat wordt, besluit ze zich onder het volk te mengen. Ze komt Aladdin tegen, en hij wordt verliefd op haar.",
            "poster_path": "/ul7ydjErplDhR22c545VrnZ5aBt.jpg"
        },
        {
            "popularity": 28.831,
            "vote_count": 2361,
            "video": false,
            "poster_path": "/ffNRNXX9fmrDc4q6TZ7s8PCasgw.jpg",
            "id": 400155,
            "adult": false,
            "backdrop_path": "/m03jul0YdVEOFXEQVUv6pOVQYGL.jpg",
            "original_language": "en",
            "original_title": "Hotel Transylvania 3: Summer Vacation",
            "genre_ids": [
                16,
                35,
                14,
                10751
            ],
            "title": "Hotel Transsylvanië 3: Zomer Vakantie",
            "vote_average": 6.8,
            "overview": "In de nieuwe animatiefilm Hotel Transsylvanië 3 reis je mee met onze favoriete monsterfamilie.  Ze vieren vakantie aan boord van een luxe cruiseschip voor monsters. Drac kan eindelijk eens bijkomen van het drukke werk in zijn hotel.",
            "release_date": "2018-06-28"
        },
        {
            "popularity": 28.295,
            "vote_count": 8440,
            "video": false,
            "poster_path": "/rcaUHXTDeKbY34zJAnL6bM61v7u.jpg",
            "id": 93456,
            "adult": false,
            "backdrop_path": "/o4DwkUXQLgsiIf1c4L9u8PtIExA.jpg",
            "original_language": "en",
            "original_title": "Despicable Me 2",
            "genre_ids": [
                16,
                35,
                10751
            ],
            "title": "Verschrikkelijke Ikke 2",
            "vote_average": 6.9,
            "overview": "Gru, het duivelse meesterbrein, is terug en heeft zijn leven gebeterd en drie kinderen geadopteerd. Samen met zijn assistent en de kinderen beleven zij een nieuw avontuur, waarbij de Minions weer voor de nodige problemen zorgen. Een paar Minions zijn aan het lanterfanten wanneer ze een geluid horen buiten. In eerste instantie blijkt het een kat te zijn die ze bang maakt, maar vervolgens worden ze ontvoerd door een mysterieuze kracht.",
            "release_date": "2013-06-25"
        },
        {
            "popularity": 27.844,
            "id": 364689,
            "video": false,
            "vote_count": 1851,
            "vote_average": 7.2,
            "title": "Ferdinand",
            "release_date": "2017-12-09",
            "original_language": "en",
            "original_title": "Ferdinand",
            "genre_ids": [
                16,
                10751,
                12,
                35
            ],
            "backdrop_path": "/sTOhZruocNubL0HxjvdouwCEL9I.jpg",
            "adult": false,
            "overview": "Ferdinand speelt zich af in Spanje en vertelt het verhaal van een grote stier met een klein hartje. Als hij ten onrechte aangezien wordt voor een gevaarlijk beest wordt hij gevangen genomen en weggevoerd.",
            "poster_path": "/rMm94JsRfcOPiPVsTRcBiiVBOhz.jpg"
        },
        {
            "popularity": 27.448,
            "id": 2062,
            "video": false,
            "vote_count": 11115,
            "vote_average": 7.7,
            "title": "Ratatouille",
            "release_date": "2007-06-22",
            "original_language": "en",
            "original_title": "Ratatouille",
            "genre_ids": [
                16,
                35,
                10751,
                14
            ],
            "backdrop_path": "/xgDj56UWyeWQcxQ44f5A3RTWuSs.jpg",
            "adult": false,
            "overview": "Remy is een rat die constant zijn leventje in het dure Franse restaurant Gusteau op het spel zet. Niet alleen vanwege zijn liefde voor lekker eten, maar ook omdat hij dolgraag zelf topkok wil zijn.",
            "poster_path": "/npHNjldbeTHdKKw28bJKs7lzqzj.jpg"
        }
    ],
    "18": [
        {
            "popularity": 60.443,
            "id": 150540,
            "video": false,
            "vote_count": 15141,
            "vote_average": 7.9,
            "title": "Binnenstebuiten",
            "release_date": "2015-06-09",
            "original_language": "en",
            "original_title": "Inside Out",
            "genre_ids": [
                18,
                35,
                16,
                10751
            ],
            "backdrop_path": "/9cGSFGZVVLsrnbs0AFPDh6X2ynl.jpg",
            "adult": false,
            "overview": "Opgroeien gaat niet zonder vallen en opstaan, en dat geldt ook voor Riley. Wanneer haar vader een nieuwe baan krijgt in San Francisco, moet ze haar leventje in het Midden-Westen van Amerika achter zich laten.",
            "poster_path": "/vrXBFt0XBuwZRGtcxnLCFY6cmLG.jpg"
        },
        {
            "popularity": 29.882,
            "id": 481848,
            "video": false,
            "vote_count": 1553,
            "vote_average": 7.4,
            "title": "The Call of the Wild",
            "release_date": "2020-02-19",
            "original_language": "en",
            "original_title": "The Call of the Wild",
            "genre_ids": [
                18,
                12,
                10751
            ],
            "backdrop_path": "/9sXHqZTet3Zg5tgcc0hCDo8Tn35.jpg",
            "adult": false,
            "overview": "Buck is een groothartige hond wiens zalige huiselijke leven op zijn kop wordt gezet wanneer hij plotseling uit zijn huis in Californië wordt ontworteld en getransplanteerd naar de exotische wildernis van de Yukon tijdens de Gold Rush van de jaren 1890. Als de nieuwste groentje op een hondenslee-team voor postbezorging - en later de leider - beleeft Buck het avontuur van zijn leven, uiteindelijk zijn ware plek in de wereld vinden en zijn eigen meester worden.",
            "poster_path": "/xvHYrJ5TaOshtJm2pEP3wMwWKfU.jpg"
        },
        {
            "popularity": 29.872,
            "vote_count": 5354,
            "video": false,
            "poster_path": "/ryKwNlAfDXu0do6SX9h4G9Si1kG.jpg",
            "id": 150689,
            "adult": false,
            "backdrop_path": "/q7vmCCmyiHnuKGMzHZlr0fD44b9.jpg",
            "original_language": "en",
            "original_title": "Cinderella",
            "genre_ids": [
                18,
                14,
                10749,
                10751
            ],
            "title": "Cinderella",
            "vote_average": 6.8,
            "overview": "Cinderella is een weeskind dat door haar stiefmoeder en stiefzussen onrechtvaardig wordt behandeld. Op een dag ontmoet ze in het bos een charmante vreemdeling met wie ze zich gelijk verwant voelt.",
            "release_date": "2015-03-12"
        }
    ],
    "28": [
        {
            "popularity": 99.954,
            "vote_count": 5060,
            "video": false,
            "poster_path": "/aQvJ5WPzZgYVDrxLX4R6cLJCEaQ.jpg",
            "id": 454626,
            "adult": false,
            "backdrop_path": "/stmYfCUGd8Iy6kAMBr6AmWqx8Bq.jpg",
            "original_language": "en",
            "original_title": "Sonic the Hedgehog",
            "genre_ids": [
                28,
                35,
                878,
                10751
            ],
            "title": "Sonic the Hedgehog",
            "vote_average": 7.5,
            "overview": "Sonic is een super snelle antropomorfe blauwe egel. De overheid is erop gebrand om Sonic te pakken te krijgen. Sheriff Tom Wachowski, een sheriff uit het klein stadje Green Hills, besluit om af te reizen naar San Francisco om Sonic te helpen in zijn strijd tegen de kwaadaardige Dr. Ivo Robotnik.",
            "release_date": "2020-02-12"
        },
        {
            "popularity": 36.719,
            "id": 9806,
            "video": false,
            "vote_count": 12678,
            "vote_average": 7.7,
            "title": "The Incredibles",
            "release_date": "2004-11-05",
            "original_language": "en",
            "original_title": "The Incredibles",
            "genre_ids": [
                28,
                12,
                16,
                10751
            ],
            "backdrop_path": "/qEqrnYQWxHFBss0ugQsLORAI6aL.jpg",
            "adult": false,
            "overview": "Bob Parr en zijn vrouw Helen behoorden vroeger tot de groep van 's werelds grootste misdaadbestrijders. Levens redden en het kwaad bestrijden was hun dagelijks werk. Maar vijftien jaar later zijn ze gedwongen om als gewone burgers te leven en zich terug te trekken in een buitenwijk. Ze wonen daar als \"normaal\" gezin met hun drie kinderen, Violet, Dash en Jack Jack.",
            "poster_path": "/2LqaLgk4Z226KkgPJuiOQ58wvrm.jpg"
        },
        {
            "popularity": 34.564,
            "vote_count": 10066,
            "video": false,
            "poster_path": "/ik8PugpL41s137RAWEGTAWu0dPo.jpg",
            "id": 102651,
            "adult": false,
            "backdrop_path": "/xjotE7aFdZ0D8aGriYjFOtDayct.jpg",
            "original_language": "en",
            "original_title": "Maleficent",
            "genre_ids": [
                28,
                12,
                14,
                10749,
                10751
            ],
            "title": "Maleficent",
            "vote_average": 7.1,
            "overview": "De Disney-klassieker 'Sleeping Beauty' vanuit het perspectief van de boze heks Maleficent, de heks die op de geboortedag van 'Doornroosje' een vloek uitspreekt dat het meisje zich op haar zestiende zal prikken aan een spinnewiel en zal sterven. Hoe komt het dat ze zo hard en meedogenloos is geworden en waarom heeft ze het jonge babyprinsesje eigenlijk vervloekt?",
            "release_date": "2014-05-28"
        },
        {
            "popularity": 32.063,
            "vote_count": 425,
            "video": false,
            "poster_path": "/n2C6jRK9PtPIs99RQhKtqGlsnsO.jpg",
            "id": 592834,
            "adult": false,
            "backdrop_path": "/bFxGD1TKrJq3Dr5Pjnn739gBhQF.jpg",
            "original_language": "en",
            "original_title": "My Spy",
            "genre_ids": [
                28,
                35,
                10751
            ],
            "title": "My Spy",
            "vote_average": 7.1,
            "overview": "JJ is een van de meest gerenommeerde agenten bij de CIA. Maar wanneer een missie uit de hand loopt krijgt hij nog één kans zijn carrière te redden. Hij wordt op een undercoveroperatie gezet om een gevaarlijke familie van wapenhandelaars te observeren. Al snel wordt hij ontmaskerd door het moedige negenjarige dochtertje Sophie.",
            "release_date": "2020-01-09"
        },
        {
            "popularity": 29.705,
            "id": 260513,
            "video": false,
            "vote_count": 8719,
            "vote_average": 7.5,
            "title": "Incredibles 2",
            "release_date": "2018-06-14",
            "original_language": "en",
            "original_title": "Incredibles 2",
            "genre_ids": [
                28,
                12,
                16,
                10751
            ],
            "backdrop_path": "/mabuNsGJgRuCTuGqjFkWe1xdu19.jpg",
            "adult": false,
            "overview": "De superhelden zijn terug en stelen opnieuw de show in Incredibles 2. In dit nieuwe avontuur staat Helen in de spotlight als superheld, terwijl Bob dagelijkse heldendaden verricht in het huishouden, met Violet, Dash en baby Jack-Jack, wiens superkrachten nog ontdekt moeten worden.",
            "poster_path": "/9lFKBtaVIhP7E2Pk0IY1CwTKTMZ.jpg"
        },
        {
            "popularity": 27.713,
            "id": 308531,
            "video": false,
            "vote_count": 2312,
            "vote_average": 5.9,
            "title": "Teenage Mutant Ninja Turtles: Out of the Shadows",
            "release_date": "2016-06-01",
            "original_language": "en",
            "original_title": "Teenage Mutant Ninja Turtles: Out of the Shadows",
            "genre_ids": [
                28,
                12,
                35,
                10751,
                878
            ],
            "backdrop_path": "/41objfan7PDTBG5MFXQl4ownQzJ.jpg",
            "adult": false,
            "overview": "Slechterik Shredder ontsnapt uit hechtenis en sluit zich aan bij de gestoorde wetenschapper Baxter Stockman en zijn twee onnozele handlangers, Bebop en Rocksteady. De Turtles proberen het duivelse plan van Shredder en zijn nieuwe crew om de wereld over te nemen tegen te houden, maar dan komt er nog groter kwaad op hun pad: de beruchte Krang.",
            "poster_path": "/euVaCiCWz3AALcQXHT6aUqdGUo6.jpg"
        }
    ],
    "35": [
        {
            "popularity": 34.971,
            "id": 448119,
            "video": false,
            "vote_count": 1859,
            "vote_average": 6.9,
            "title": "Dolittle",
            "release_date": "2020-01-01",
            "original_language": "en",
            "original_title": "Dolittle",
            "genre_ids": [
                35,
                14,
                12,
                10751
            ],
            "backdrop_path": "/xcUf6yIheo78btFqihlRLftdR3M.jpg",
            "adult": false,
            "overview": "Na het verliezen van zijn vrouw zondert John Dolittle, een dokter uit het Victoriaanse tijdperk, zich af van de wereld met enkel zijn exotische dieren als gezelschap. Wanneer de jonge koningin Victoria echter ernstig ziek is, wordt John gedwongen af te reizen naar een mythisch eiland op zoek naar een remedie. Tijdens zijn zoektocht wordt hij vergezeld door een jonge leerling en een gezelschap aan dieren, waaronder een angstige gorilla, een enthousiaste eend, een cynische struisvogel, een vrolijke ijsbeer en een eigenzinnige papegaai.",
            "poster_path": "/uoplwswBDy7gsOyrbGuKyPFoPCs.jpg"
        },
        {
            "popularity": 32.581,
            "vote_count": 3297,
            "video": false,
            "poster_path": "/wA4lgl8gmoICSShviCkEB61nIBB.jpg",
            "id": 9880,
            "adult": false,
            "backdrop_path": "/ktPixrzD9Rbet7Co4uUWxyR9191.jpg",
            "original_language": "en",
            "original_title": "The Princess Diaries",
            "genre_ids": [
                35,
                10749,
                10751
            ],
            "title": "The Princess Diaries",
            "vote_average": 6.8,
            "overview": "Een 16-jarig meisje dat wordt opgevoed door haar alleenstaande moeder komt er op een dag achter dat ze de prinses is van een klein Europees landje. Ze moet nu een keus maken tussen haar leven als tiener in San Francisco, of de troon bestijgen in Europa. Terwijl Mia haar keuze probeert te maken krijgt ze prinses-lessen van haar grootmoeder.",
            "release_date": "2001-08-03"
        }
    ],
    "10751": [
        {
            "popularity": 33.01,
            "id": 404368,
            "video": false,
            "vote_count": 4621,
            "vote_average": 7.2,
            "title": "Ralph Breaks the Internet",
            "release_date": "2018-11-20",
            "original_language": "en",
            "original_title": "Ralph Breaks the Internet",
            "genre_ids": [
                10751,
                16,
                35,
                12
            ],
            "backdrop_path": "/88poTBTafMXaz73vYi3c74g0y2k.jpg",
            "adult": false,
            "overview": "Deze film speelt zich zes jaar na de eerste film af. Ralph en co. zetten hun avonturen voort en hoppen nog steeds van het ene naar het andere arcadespel. Ze ontdekken echter een nieuw fenomeen: het internet.",
            "poster_path": "/qEnH5meR381iMpmCumAIMswcQw2.jpg"
        },
        {
            "popularity": 32.495,
            "id": 11224,
            "video": false,
            "vote_count": 4549,
            "vote_average": 7,
            "title": "Assepoester",
            "release_date": "1950-02-22",
            "original_language": "en",
            "original_title": "Cinderella",
            "genre_ids": [
                10751,
                14,
                16,
                10749
            ],
            "backdrop_path": "/wQraxOsaq0NwTO9rSwLo4Nc8hVG.jpg",
            "adult": false,
            "overview": "Assepoester is de geadopteerde dochter van een vrouw van hoge afkomst. Ze wordt als slaaf behandeld in haar eigen huis. Terwijl haar moeder en haar slechte stiefzusters naar een groot bal zijn krijgt ze een verrassend bezoek van een vriend. Disney versie van het bekende sprookje.",
            "poster_path": "/7N4aVPlHjcDxXuVgmRc8y06lRmS.jpg"
        },
        {
            "popularity": 31.782,
            "id": 321612,
            "video": false,
            "vote_count": 12577,
            "vote_average": 6.9,
            "title": "Beauty and the Beast",
            "release_date": "2017-03-16",
            "original_language": "en",
            "original_title": "Beauty and the Beast",
            "genre_ids": [
                10751,
                14,
                10749
            ],
            "backdrop_path": "/7QshG75xKCmClghQDU1ta2BTaja.jpg",
            "adult": false,
            "overview": "Belle, een mooie, jonge onafhankelijke vrouw wordt gevangen genomen door een beest in zijn kasteel. Ondanks een beangstigend gevoel van onzekerheid over wat haar te wachten staat raakt ze bevriend met het betoverde personeel in het kasteel. Ze leert verder te kijken dan de afschuwelijke buitenkant van het beest en ontdekt de vriendelijke ziel van een prins die binnen in hem schuilt.",
            "poster_path": "/gZVn8EDK1VsWbb7av92mklvuLIu.jpg"
        },
        {
            "popularity": 31.24,
            "id": 270946,
            "video": false,
            "vote_count": 2797,
            "vote_average": 6.5,
            "title": "Penguins of Madagascar",
            "release_date": "2014-11-22",
            "original_language": "en",
            "original_title": "Penguins of Madagascar",
            "genre_ids": [
                10751,
                16,
                12,
                35
            ],
            "backdrop_path": "/77KUH3Dheen9duqXitJD4nZHuom.jpg",
            "adult": false,
            "overview": "De vier pinguïns, Skipper, Rico, Kowalski en Private bundelen hun krachten met de chique spionagedienst de 'North Wind' die onder leiding staat van agent Classified. Samen moeten ze het opnemen tegen de kwaadaardige Dr. Octavius Brine die de wereld wil veroveren.",
            "poster_path": "/7pE1sSAR8AMA9M0StD9kfal9o1n.jpg"
        }
    ]
};
