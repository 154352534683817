import React from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js'
import styles from './VideoPlayer.module.scss'
import { withRouter } from 'react-router-dom';

class VideoPlayer extends React.Component {
    componentDidMount() {
        this.loadPlayer();
    }

    componentWillUnmount() {
        try {
            this.player.dispose()
        } catch (e) {}
    }

    loadPlayer = () => {
        const options = {
            autoplay: true,
            controls: true,
            sources: [{
                src: this.props.src,
                type: `video/${this.props.srcType}`
            }]
        };
        const onPlayerReady = () => {
            const vid = document.getElementsByTagName("video").item(0);
            vid.disablePictureInPicture = true
        };
        this.player = videojs(this.videoNode, options, onPlayerReady);
    };

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div className={styles.PlayerWrapper}>
                <span className={styles.PreviousLink} onClick={this.props.history.goBack}>X</span>
                <div data-vjs-player>
                    <video
                        disablePictureInPicture
                        ref={ node => this.videoNode = node }
                        className="video-js vjs-fill vjs-big-play-centered"
                    ></video>
                </div>
            </div>
        )
    }
}

VideoPlayer.propTypes = {
    srcType: PropTypes.string,
    src: PropTypes.string.isRequired,
};

VideoPlayer.defaultProps = {
    srcType: 'mp4'
};

export default withRouter(VideoPlayer)
