import { matchPath } from 'react-router';
import sortBy from 'lodash/sortBy'
import { formatRoute } from 'react-router-named-routes';
import {
    RouteProps,
    RoutesMap,
    RoutesMapWithPathResolvers
} from "../types";
import cloneDeep from 'lodash/cloneDeep';

export const setRouteDefaults = (routeMap: RoutesMap): RoutesMap => {
    const result = cloneDeep(routeMap);
    Object.keys(result).forEach(key => {
        if (typeof result[key].displayNav !== "boolean") {
            result[key].displayNav = true
        }
        if (typeof result[key].authenticatedOnly !== "boolean") {
            result[key].authenticatedOnly = true
        }
    });
    return result;
};

export const getActiveRoute = (path, routes: RouteProps[]): RouteProps | undefined =>
    sortBy(routes, 'exact')
        .reverse()
        .find(route => matchPath(path, route.path));

export const addPathResolvers = (routeMap: RoutesMap): RoutesMapWithPathResolvers => {
    const newRouteMap = {};
    Object.keys(routeMap).forEach(key => {
        newRouteMap[key] = {
            ...routeMap[key],
            /**
             * use getPath to get the path of a route. Example:
             * <Link to={routes.dashboard.formatRoute()} />
             * or if route accept params like "user/:id":
             * <Link to={routes.userDetails.formatRoute({ id: 1 })} />
             */
            getPath: (params: object | undefined) =>
                routeMap[key].getPath ||
                formatRoute(routeMap[key].path, params),
        }
    });
    return newRouteMap;
};
