import React from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { logout } from "../redux";
import { useDispatch } from "react-redux";
import routes from "../../../routes";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Logout = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(logout());
        history.push(routes.login.getPath())
    });
    return (
        <Container>
            <h5>Logged out</h5>
        </Container>
    )
};

export default Logout;
