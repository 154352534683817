import React from 'react';
import { connect } from "react-redux";
import { closeModal } from "../redux";
import { Modal } from "react-bootstrap";
import { Modal as ModalType } from "../types";

interface ModalContainerProps {
    modals: ModalType[];
    closeModal: (id: number) => {};
}

const ModalContainer = ({ modals, closeModal }: ModalContainerProps) => {
    const createClose = (modal: ModalType) => () => {
        closeModal(modal.id);
        if (modal.callback) {
            modal.callback();
        }
    };

    return (
        <>
            {modals.map(modal =>
                <Modal
                    show={true}
                    key={modal.id}
                    onHide={createClose(modal)}
                    centered
                >
                    { modal.header && <Modal.Header closeButton>
                        <Modal.Title>{modal.header}
                        </Modal.Title>
                    </Modal.Header> }

                    <Modal.Body>
                        <modal.Component close={createClose(modal)} />
                    </Modal.Body>

                    {/*<Modal.Footer>*/}
                    {/*  <Button variant="secondary">Close</Button>*/}
                    {/*  <Button variant="primary">Save changes</Button>*/}
                    {/*</Modal.Footer>*/}
                </Modal>
            )}
        </>
    )
};

// @ts-ignore
const mapStateToProps = (state) => ({
    modals: state.modals || [],
});

const mapDispatchToProps = dispatch => ({
    closeModal: (id: number) => dispatch(closeModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
