import React from "react";
import ReactDOM from "react-dom";
import "./CollectionPreview.scss";
import CollectionItem from "../CollectionItem/CollectionItem";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TitleHide } from "../../../utils/TitleHide";
import { LeftArrow, RightArrow } from "../../../utils/ScrollArrows";
import Fade from "react-reveal/Fade";

class CollectionPreview extends React.Component {
    constructor() {
        super();
        this.divRef = React.createRef();
    }

    componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
        var node = ReactDOM.findDOMNode(this);
        TitleHide(node);
    }

  onLeftClick = () => {
      LeftArrow(this.divRef);
  };

  onRightClick = () => {
      RightArrow(this.divRef);
  };

  render() {
      const  { items, title }  = this.props;
      return (
          <Fade>
              <div className="collection-preview">
                  <h1 className="collection-preview__title">{title.toUpperCase()}</h1>

                  <div className="collection-preview__box">
                      <span className="collection-preview__left-arrow">
                          <FontAwesomeIcon
                              icon={faChevronLeft}
                              className="collection-preview__left-arrow-icon"
                              onClick={this.onLeftClick}
                          />
                      </span>
                      <span className="collection-preview__right-arrow">
                          <FontAwesomeIcon
                              icon={faChevronRight}
                              className="collection-preview__right-arrow-icon"
                              onClick={this.onRightClick}
                          />
                      </span>
                      <div className="collection-preview__inner" ref={this.divRef}>
                          { items.map(item => (
                              <CollectionItem
                                  key={item.id}
                                  item={item}
                              />
                          ))}
                      </div>
                  </div>
              </div>
          </Fade>
      );
  }
}

export default CollectionPreview;
