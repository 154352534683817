import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
} from 'react-router-dom';
import styled from 'styled-components';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { withNavigation } from 'react-tv-navigation'

import Nav from "../components/Nav";
import NotFound from '../components/NotFound';
import { routeList } from '../routes';
import { RouteProps } from "../types";
import ModalContainer from "../features/modals/components/ModalContainer";
import env from "../constants/env";
import AuthenticatedOnly from "../features/users/components/AuthenticatedOnly";

const createTitle = (routeTitle: string) => {
    const titlePrefix = routeTitle && `${routeTitle} | `;
    return `${titlePrefix}${env.appName}`;
};

const createRouteRenderer = (route: RouteProps) => (props: any) => {
    const Component = route.component || NotFound;
    const AuthGuard = route.authenticatedOnly ? AuthenticatedOnly : React.Fragment;
    return (
        <AuthGuard>
            <Helmet>
                <title>{ createTitle(route.title || '') }</title>
            </Helmet>
            <Component {...props} />
        </AuthGuard>
    )
};

const PageWrapper = styled.div`
  padding: 0px
`;

// TODO load videos to reducer
const App = () =>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <Nav/>
        <ModalContainer/>
        <PageWrapper>
            <Switch>
                {
                    routeList.map((route, index) => (
                        <Route
                            path={route.path}
                            exact={route.exact}
                            render={createRouteRenderer(route)}
                            key={index}
                        />
                    ))
                }
                <Route path="*" component={NotFound} />
            </Switch>
        </PageWrapper>
    </BrowserRouter>;

export default withNavigation(App);
