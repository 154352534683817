import React from "react";
import "./SignUp.scss";
import { Link, useHistory } from "react-router-dom";
import FormInput from "../../../../components/netflix/FormInput/FormInput";
import CustomButton from "../../../../components/netflix/CustomButton/CustomButton";
import backgroundImage from "../../../../assets/bg.jpg";
import { useDispatch } from "react-redux";
import { DUMMY_USER, setCurrentUser } from "../../redux";
import routes from "../../../../routes";

const SignUp = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const submit = (e) => {
        e.preventDefault();
        dispatch(setCurrentUser(DUMMY_USER));
        history.push(routes.home.getPath());
    };
    const { displayName, email, password, confirmPassword } = {
        ...DUMMY_USER,
        displayName: "displayName",
        password: "algnaslkgnasd",
        confirmPassword: "algnaslkgnasd",
    };
    return (
        <div className="signup">
            <div
                className="signin__bg"
                style={{  backgroundImage: `url(${backgroundImage})` }}
            />
            <div className="signup__container">
                <div className="signup__shadow">
                    <h1 className="signup__title">Sign Up</h1>

                    <form onSubmit={submit}>
                        <FormInput
                            name="displayName"
                            type="name"
                            value={displayName}
                            handleChange={submit}
                            label="Name"
                            required
                        />

                        <FormInput
                            name="email"
                            type="email"
                            value={email}
                            handleChange={submit}
                            label="Email"
                            required
                        />

                        <FormInput
                            name="password"
                            type="password"
                            value={password}
                            handleChange={submit}
                            label="Password"
                            required
                        />

                        <FormInput
                            name="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            handleChange={submit}
                            label="Confirm Password"
                            required
                        />

                        <div className="signup__btn-container">
                            <div className="signup__btn">
                                <CustomButton type="submit"> Sign Up </CustomButton>
                            </div>
                        </div>
                    </form>
                    <div className="signup__option">
                        <span className="signup__option--newuser">
                            Already signed up?
                        </span>
                        <Link to="/signin" className="signup__option--link">
                            Log in now.
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
