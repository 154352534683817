import { AddModal, Modal } from "./types";

const ADD_MODAL = 'ADD_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
let _nextId = 0;
let _nextZIndex = 99;
export const addModal = (modal: AddModal) => ({
    type: ADD_MODAL,
    modal: {
        id: ++_nextId,
        zIndex: ++_nextZIndex,
        ...modal
    } as Modal,
});

export const closeModal = (id: number) => ({
    type: CLOSE_MODAL,
    id,
});

export const modalsReducer = (state = [], action) => {
    const addAction = (action) => [...state, action.modal];
    const removeAction = ({ id }) => state.filter(m => m.id !== id);

    switch (action.type) {
    case ADD_MODAL: return addAction(action);
    case CLOSE_MODAL: return removeAction(action);
    default: return state
    }
};
