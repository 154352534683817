import React from "react";
import "./CollectionGrid.scss";
import CollectionGridItem from "../CollectionGridItem/CollectionGridItem";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeftGridArrow, RightGridArrow } from "../../../utils/GridScrollArrows";

class CollectionGrid extends React.Component {
    constructor() {
        super();
        this.gridRef = React.createRef();
    }

    render() {
        const items = this.props.items || [];
        return (
            <div className="collection-grid">
                <span className="collection-grid__left-arrow">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="collection-grid__left-arrow--icon"
                        onClick={() => LeftGridArrow(this.gridRef)}
                    />
                </span>
                <div className="collection-grid__outer" ref={this.gridRef}>
                    <div className="collection-grid__inner">
                        { items.map(item => <CollectionGridItem key={item.id} item={item} /> )}
                    </div>
                    <span className="collection-grid__right-arrow">
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className="collection-grid__right-arrow--icon"
                            onClick={() => RightGridArrow(this.gridRef)}
                        />
                    </span>
                </div>
            </div>
        );
    }
}

export default CollectionGrid;
