import React from "react";
import "./AddToList.scss";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { addPlaylist, removePlaylist } from "../../redux";

const AddToList = ({ addItem, removeItem, item, playlist = [] }) => {
    const isAdded = playlist.filter(id => id == item.id).length > 0;
    const iconStyle = {height: 18, width: 18};
    const onClick = isAdded 
        ? () => removeItem(item.id)
        : () => addItem(item.id);
    const icon = isAdded ? faCheck : faPlus;
    const text = isAdded ? 'Added to list' : 'Add to list';
    return (
        <div className="addtolist">
            <div className="addtolist__container" onClick={onClick}>
                <FontAwesomeIcon style={iconStyle} icon={icon} className="addtolist--icon" />
                <span>{text}</span>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    addItem: item => dispatch(addPlaylist(item)),
    removeItem: item => dispatch(removePlaylist(item))
});

const mapStateToProps = state => ({
    playlist: state.playlist
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToList);
