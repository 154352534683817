import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect'
import { useSelector } from "react-redux";

const PLAYLIST_ACTIONS = {
    add: "PLAYLIST_ADD",
    remove: "PLAYLIST_REMOVE",
};

export const addPlaylist = (id) => ({
    type: PLAYLIST_ACTIONS.add,
    id,
});

export const removePlaylist = (id) => ({
    type: PLAYLIST_ACTIONS.remove,
    id,
});

export const useSelectPlaylistItems = () => useSelector(createSelector(
    (state: any) => state.videos,
    (state: any) => state.playlist,
    (videos, playlist) => videos.filter(({ id }) => playlist.includes(id))
));

export const playlistReducer = (state = [], action) => {
    // @ts-ignore
    const add = (action) => console.log(uniqBy([...state, action.id])) || uniqBy([...state, action.id]);
    const remove = (action) => state.filter(id => id !== action.id);
    switch (action.type) {
    case PLAYLIST_ACTIONS.add: return add(action);
    case PLAYLIST_ACTIONS.remove: return remove(action);
    default: return state
    }
};
