import { init, captureException } from '@sentry/browser';

const isProduction = process.env.REACT_APP_ENV === 'production';

export const setupErrorTracking = () => {
    if (isProduction) {
        init({ dsn: 'https://e46cbb5703b747a093aa1bbb25861f6e@o336445.ingest.sentry.io/5259595' });
    }
};

export const captureError = (e: Error | string) => {
    const error = typeof e === 'string' ? new Error(e) : e;
    if (isProduction) {
        captureException(error);
    } else {
        console.error(error);
    }
};
