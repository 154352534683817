import React  from "react";
import { movieCategories } from "../../../dummyData";
import CollectionGrid
    from "../../../components/netflix/CollectionGrid/CollectionGrid";
import CollectionOverview
    from "../../../components/netflix/CollectionOverview/CollectionOverview";

const Videos = () =>
    <div>
        <CollectionGrid items={movieCategories["12"]} />
        <CollectionOverview videoCategories={movieCategories} />
    </div>;

export default Videos;
