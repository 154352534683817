import React from 'react';
import {Link} from "react-router-dom";
import routes from "../../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";


const PlayLink = ({ video }) =>
    <Link to={routes.videoPlayer.getPath({ id: video.id })} style={{display: "flex", color: "white"}}>
        <FontAwesomeIcon
            style={{width: 13, height: 13, marginRight: 6, display: "flex", alignItems: "center", flexDirection: "row"}}
            icon={faPlay}/>
        <span>Play</span>
    </Link>;
    
export default PlayLink;
