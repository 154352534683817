import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { matchPath } from 'react-router';
import { withFocusable } from 'react-tv-navigation'
import { RoutePropsWithPathResolver } from "../types";
import { navRoutes, offlineNavRoutes, routeList } from "../routes";
import BSNav from 'react-bootstrap/Nav'
import { Form, FormControl, Navbar } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { getActiveRoute } from "../utils/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import UserMenu from "../features/users/components/UserMenu";
import { useSelector } from "react-redux";
import { isLoggedInSelector } from "../features/users/redux";

const NavLink = withFocusable(({ route, focused }) => {
    const history = useHistory();
    const isRouteActive = (route: RoutePropsWithPathResolver) => matchPath(history.location.pathname, route);
    const getRouteStyle = route =>
        isRouteActive(route)
            ? { fontWeight: 900, color: 'white' }
            : { color: 'white' };
    return (
        <BSNav.Item key={`${route.path}`}>
            <BSNav.Link
                as={Link}
                style={getRouteStyle(route)}
                to={route.getPath()}
            >{route.title} {focused ? 't' : ''}</BSNav.Link>
        </BSNav.Item>
    )
});

const Focusable = withFocusable(({ focused, setFocus, focusPath, children }) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
});

const FocusableButton = withFocusable(props => {
    const { focused, setFocus, focusPath } = props;
    if (focused && props.onClick) {
        props.onClick();
    }
    return <Button {...props}/>;
});

const Search = ({ submitSearch }) => {
    return (
        <div>
            <Form inline style={{ marginRight: 24 }}>
                <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                />

                <FocusableButton variant="secondary">
                    <FontAwesomeIcon
                        icon={faSearch}
                        onClick={submitSearch}
                    />
                </FocusableButton>
            </Form>
        </div>
    );
};

const Nav = () => {
    const history = useHistory();
    const route = getActiveRoute(history.location.pathname, routeList) || { displayNav: true };
    const submitSearch = () => { alert("Submit Search") };
    const isLoggedIn = useSelector(isLoggedInSelector());
    const routes = isLoggedIn ? navRoutes : offlineNavRoutes;
    return !route.displayNav ? null : (
        <Navbar
            variant="dark"
            fixed="top"
            style={{ backgroundColor: 'rgba(0,0,0,0.25)' }}
        >
            <Navbar.Brand as={Link} to="/">SlowNet</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <BSNav className="mr-auto">
                    { routes.map(route => <NavLink route={route} key={route.path}/> )}
                </BSNav>

                {isLoggedIn &&
                <React.Fragment>
                    <Search submitSearch={submitSearch}/>
                    <UserMenu/>
                </React.Fragment>
                }
            </Navbar.Collapse>
        </Navbar>
    )
};

export default Nav;
