import React from "react";
import "./CollectionOverview.scss";
import CollectionPreview from "../CollectionPreview/CollectionPreview";

const CollectionOverview = ({ videoCategories }) =>
    <div className="collection-overview">
        { Object.keys(videoCategories).map(key => (
            <CollectionPreview
                key={key}
                title={key}
                items={videoCategories[key]}
            />
        ))}
    </div>;

export default CollectionOverview;
