import React from "react";
import { IMAGE_BASE_URL, GRID_IMAGE_SIZE } from "../../../constants/config";
import "./CollectionItem.scss";
import Truncate from "../../../utils/Truncate";
import { withRouter } from "react-router";
import AddToList from "../../../features/playlist/components/AddToList/AddToList";
import { Link } from "react-router-dom";
import routes from "../../../routes";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlayLink from "../../../features/videoplayer/components/PlayLink";

const CollectionItem = ({ item, history }) => {
    const { title, overview, backdrop_path, name, id } = item;
    const para = Truncate(overview, 170, "...");
    const iconStyle = {width: 13, height: 13, marginRight: 6, display: "flex", alignItems: "center", flexDirection: "row"};
    return (
        <div className="collection-item">
            <img
                src={`${IMAGE_BASE_URL}${GRID_IMAGE_SIZE}${backdrop_path}`}
                alt="movie"
                className="collection-item__movie-image"
            />
            <div className="collection-item__text">
                <h1 className="collection-item__title">{title}</h1>
                <h1 className="collection-item__title">{name}</h1>
                <span className="collection-item__overview">{para}</span>
            </div>
            <div className="collection-item__addtolist">
                <PlayLink video={item} />
                <AddToList item={item} />
            </div>
        </div>
    );
};

export default withRouter(CollectionItem);
