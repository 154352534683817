import { applyMiddleware, combineReducers, createStore } from 'redux';
import { modalsReducer } from "./features/modals/redux";
import { userReducer } from "./features/users/redux";
import { videoCategoriesReducer, videosReducer } from "./features/videos/redux";
import { persistReducer, persistStore } from "redux-persist";
import { playlistReducer } from "./features/playlist/redux";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';

const persistConfig = {
    key: "rootzt",
    storage,
    // whitelist: ["list", "search"]
};

const rootReducer = combineReducers({
    modals: modalsReducer,
    user: userReducer,
    playlist: playlistReducer,
    videos: videosReducer,
    videoCategories: videoCategoriesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
export default store
export type RootState = ReturnType<typeof rootReducer>
