import React from 'react'
import styled from 'styled-components';
import { withFocusable, withNavigation } from 'react-tv-navigation'

const SubItem = ({focused, setFocus, focusPath, openParent}) => {
    if (focused) {
        openParent(true);
    }
    focused = (focused) ? 'focused' : 'unfocused';
    return (
        <div onClick={() => { setFocus() }} >
            Its {focused} Item
        </div>
    )
};

const FocusableSubItem = withFocusable(SubItem);

const Item = ({focused, setFocus, focusPath}) => {
    const [open, doOpen] = React.useState(false);
    if (focused && !open) {
        doOpen(true);
    }
    const b = focused || open;
    focused = (focused) ? 'focused' : 'unfocused';
    return (
        <div onClick={() => { setFocus() }} style={{height: 99}}>
            Its {focused} Item
            {
                b ?
                    <div>
                        <FocusableSubItem openParent={doOpen} focusPath={`${focusPath}_1`}/>
                        <FocusableSubItem openParent={doOpen} focusPath={`${focusPath}_2`}/>
                        <FocusableSubItem openParent={doOpen} focusPath={`${focusPath}_3`}/>
                    </div> : null
            }
        </div>
    )
};

const Button = ({setFocus}) => {
    return (
        <div onClick={() => { setFocus('item-1') }}>
            Back To First Item!
        </div>
    )
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const FocusableItem = withFocusable(Item);
const FocusableButton = withFocusable(Button);

function App({currentFocusPath}) {
    const r = new Array(9).fill(null);
    return (
        <div>
            <h1>Current FocusPath: {currentFocusPath}</h1>,
            {
                r.map((_, i1) =>
                    <Row>{ r.map((_, i2) => <FocusableItem focusPath={`item-${i1}${i2}`}/>) }</Row>
                )
            }
            <FocusableItem focusPath='item-1'/>
            <FocusableItem focusPath='item-2'/>
            <FocusableButton
                focusPath='button'
                onEnterPress={() => console.log('Pressed enter on Button!')}/>
        </div>
    )
}

const NavigableApp = withNavigation(App);
export default NavigableApp;
