import React from "react";
import "./WatchVideo.scss";
import VideoPlayer from "../../../features/videoplayer/components/VideoPlayer/VideoPlayer";

class WatchVideo extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <VideoPlayer src="https://upload.wikimedia.org/wikipedia/commons/transcoded/c/c0/Big_Buck_Bunny_4K.webm/Big_Buck_Bunny_4K.webm.360p.vp9.webm" />
        );
    }
}

export default WatchVideo;
