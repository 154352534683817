import React from "react";
import { IMAGE_BASE_URL, POSTER_SIZE } from "../../../constants/config";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import routes from "../../../routes";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {useDispatch} from "react-redux";
import {removePlaylist} from "../../../features/playlist/redux";

const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
`;

const HoverOverlay = styled.div`
  transition: all 0.25s ease-in;
  position: absolute;
  color: white;
  bottom: 16%;
  text-shadow: 5px 5px 6px black;
  cursor: pointer;
  opacity: 0;
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 5;
  
   ${Item}:hover & {
     opacity: 1;
  }
`;

const OverlayAction = styled.span`
  transition: all 0.25s ease-in;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 12px;

  &:hover {
    transform: scale(1.25);
  }
`;

const Image = styled.img`
    width: 240px;
    height: 360px;
    transition: opacity 0.25s ease-in;

    @media screen and (max-width: 800px) {
      width: 200px;
      height: 320px;
    }
    
   ${Item}:hover & {
     opacity: .5;
   }
`;

// eslint-disable-next-line react/prop-types
const ListItem = ({ item }) => {
    const { id, poster_path } = item;
    const dispatch = useDispatch();
    const removeItem = () => dispatch(removePlaylist(id));
    return (
        <Item>
            <HoverOverlay>
                <OverlayAction onClick={removeItem}>
                    <FontAwesomeIcon style={{marginRight: 12}} icon={faTimes} />
                    <span>Remove</span>
                </OverlayAction>

                <Link to={routes.videoPlayer.getPath({ id: item.id })}>
                    <OverlayAction>
                        <FontAwesomeIcon style={{marginRight: 12}} icon={faPlay} />
                        <span>Play</span>
                    </OverlayAction>
                </Link>
            </HoverOverlay>

            <Link to={routes.videoPlayer.getPath({ id: item.id })}>
                <Image
                    src={`${IMAGE_BASE_URL}${POSTER_SIZE}${poster_path}`}
                    alt="movie"
                />
            </Link>
        </Item>
    );
};

export default ListItem;
