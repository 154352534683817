import Videos from "./views/Videos";
import NavigableApp from "./views/TVNavigation";

const routes = {
    home: {
        title: 'Movies',
        path: '/',
        component: Videos,
        includeInNav: true,
        exact: true,
    },
    test: {
        title: 'Test',
        path: '/test',
        component: NavigableApp,
        includeInNav: true,
        exact: true,
    },
};

export default routes;
