import WatchVideo from "./views/WatchVideo";

const routes = {
    videoPlayer: {
        title: 'Movie',
        path: '/film/:id',
        component: WatchVideo,
        exact: true,
        displayNav: false,
    },
};

export default routes;
