import Playlist from "./views/Playlist";

const routes = {
    playList: {
        title: 'Playlist',
        path: '/playlist',
        component: Playlist,
        includeInNav: true,
        exact: true,
    },
};

export default routes;
