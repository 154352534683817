import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { setupErrorTracking } from "./utils/errorTracker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

setupErrorTracking();
ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
